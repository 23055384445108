import React, { createContext, useContext, useState } from 'react';
import {getTokenFromLS} from '../restApis/api'
import jwt_decode from 'jwt-decode';

const StateContext = createContext();

const initialState = {
  chat: false,
  cart: false,
  userProfile: false,
  notification: false,
};



export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentColor, setCurrentColor] = useState('#03C9D7');
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [isLogoutButtonClicked, setLogoutButtonClicked] = useState(false);

  const token = getTokenFromLS(); 
  let decoded = null;
  if (token == null || token == undefined){
    decoded = []
  }else{
    decoded = jwt_decode(token.user_meta.token);
  }

  
  const [userInfo,setuserInfo] = useState(decoded);

  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  };

  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem('colorMode', color);
  };
  const InfoLoggedIn = () => {
    
    let decodedToken  = null
    if (token == null || token == undefined){
      decodedToken = []
    }else{
      decodedToken = jwt_decode(token.user_meta.token);
    }
    setuserInfo(decodedToken);
    
  };
  const handleClick = (clicked) => setIsClicked({ ...initialState, [clicked]: true });
  if (isLogoutButtonClicked) {
    // Code to run when the "Logout" button is clicked
    console.log('Logout has been clicked');
    localStorage.clear();
    location.reload();
  
  }
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <StateContext.Provider value={
      { currentColor, 
      currentMode, 
      activeMenu, 
      screenSize, 
      setScreenSize, 
      handleClick, 
      isClicked, 
      initialState, 
      setIsClicked, 
      setActiveMenu, 
      setCurrentColor, 
      setCurrentMode, 
      setMode, 
      setColor, 
      themeSettings, 
      setThemeSettings,
      userInfo,
      InfoLoggedIn,
      isLogoutButtonClicked, setLogoutButtonClicked
      }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
