import axios from "axios";

const URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

export async function loginApi(email, password) {
  return axios({
    method: "post",
    url: URL + "/api/v1/professional/admin",
    headers: {},
    data: {
      email: email,
      password: password,
    },
  });
}

export const getTokenFromLS = () => {
  let res = localStorage.getItem("sessionItems");
  
  return JSON.parse(res);
};

export async function getPatientsApi() {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getPatients",
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}


export async function getMediaApi() {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/media",
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getCategoriesApi() {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/categories",
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
}

  export async function updateVideoCategory(data) {
    let token = getTokenFromLS(); 
    try {
      if (token == undefined || token == null) {
        return "refresh Failed"
      } 
      let tokenHeader = token.user_meta.token
      return axios({
        method: "post",
        url: URL + "/api/v1/professional/categoryVideo",
        headers: { authorization: "Bearer " + tokenHeader },
        data:data
      });
    } catch (error) {
      console.log(error);
    }
  }

  export async function updateCategoryVideo(data) {
    console.log(data)
    let token = getTokenFromLS(); 
    try {
      if (token == undefined || token == null) {
        return "refresh Failed"
      } 
      let tokenHeader = token.user_meta.token
      return axios({
        method: "post",
        url: URL + "/api/v1/professional/videoCategory",
        headers: { authorization: "Bearer " + tokenHeader },
        data:data
      });
    } catch (error) {
      console.log(error);
    }
  }

    export async function getVideoCategory(id) {
      let token = getTokenFromLS(); 
      try {
        if (token == undefined || token == null) {
          return "refresh Failed"
        } 
        let tokenHeader = token.user_meta.token
        return axios({
          method: "get",
          url: URL + "/api/v1/professional/getVideoCategory/"+id,
          headers: { authorization: "Bearer " + tokenHeader },
        });
      } catch (error) {
        console.log(error);
      }
}
export async function getCategoryVideo(id) {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getCategoryVideo/"+id,
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
}

export async function getallVideoCategories(id){
  let token = getTokenFromLS(); 
      try {
        if (token == undefined || token == null) {
          return "refresh Failed"
        } 
        let tokenHeader = token.user_meta.token
        return axios({
          method: "get",
          url: URL + "/api/v1/professional/getAllVideoCategory/"+id,
          headers: { authorization: "Bearer " + tokenHeader },
        });
      } catch (error) {
        console.log(error);
      }
}

export async function getAllCategoryVideos(id){
  let token = getTokenFromLS(); 
      try {
        if (token == undefined || token == null) {
          return "refresh Failed"
        } 
        let tokenHeader = token.user_meta.token
        return axios({
          method: "get",
          url: URL + "/api/v1/professional/getAllCategoryVideo/"+id,
          headers: { authorization: "Bearer " + tokenHeader },
        });
      } catch (error) {
        console.log(error);
      }
}

export async function saveEvent(data){
  let token = getTokenFromLS(); 
      try {
        if (token == undefined || token == null) {
          return "refresh Failed"
        } 
        let tokenHeader = token.user_meta.token
        return axios({
          method: "post",
          url: URL + "/api/v1/professional/saveEvent",
          headers: { authorization: "Bearer " + tokenHeader },
          data: data,
        });
      } catch (error) {
        console.log(error);
      }
}

export async function getEventsApi() {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getEvents",
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}

export async function getEventsDetailedApi() {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getEventsDetailed",
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}

export async function getPatientEventApi(id) {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/patientEvents/"+id,
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}
export async function getEventQuestionsApi(id) {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getEventQuestionaire/"+id,
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}
export async function getAppQuestionsApi(id) {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "get",
      url: URL + "/api/v1/professional/getAppQuestionairePatient/"+id,
      headers: { authorization: "Bearer " + tokenHeader },
    });
  } catch (error) {
    console.log(error);
  }
 
}

export async function updateSubEvent(data) {
  let token = getTokenFromLS(); 
  try {
    if (token == undefined || token == null) {
      return "refresh Failed"
    } 
    let tokenHeader = token.user_meta.token
    return axios({
      method: "put",
      url: URL + "/api/v1/professional/updateSubEvent",
      headers: { authorization: "Bearer " + tokenHeader },
      data:data
    });
  } catch (error) {
    console.log(error);
    return error;
  }
}