import React from 'react';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, LineSeries, DateTime, Legend, Tooltip,DataLabel } from '@syncfusion/ej2-react-charts';

// import { lineCustomSeries, LinePrimaryYAxis } from '../../data/dummy';
import { useStateContext } from '../../contexts/ContextProvider';

const LineChart = (props) => {
  const { currentMode } = useStateContext();
  const chartId = props.value.event_id;
 
 

  const sub_events =props.value.sub_events;
  // Create an object to store seriesData based on sub_event_startDate_Line
  const seriesDataMap = {};
  const lineCustomSeriesMe = [];
  let maxVideoWatchedTime = 0;

  // Iterate through sub_events to populate lineCustomSeries
  sub_events.forEach(sub_event => {
      const startDateLine = sub_event.sub_event_startDate_Line;
      const videoWatchedTime = convertTimeToMinutes(sub_event.sub_event_video_watched_time);

      // If seriesData for the startDateLine already exists, add the new point to it
      if (seriesDataMap[startDateLine]) {
        seriesDataMap[startDateLine].push({
          x: new Date(sub_event.sub_event_startDate_Line),
          y: videoWatchedTime,
        });
      } else {
        // If seriesData for the startDateLine doesn't exist, create a new array with the first point
        seriesDataMap[startDateLine] = [{
          x: new Date(sub_event.sub_event_startDate_Line),
          y: videoWatchedTime,
        }];
      }
       // Update the maxVideoWatchedTime if the current videoWatchedTime is greater
      if (videoWatchedTime > maxVideoWatchedTime) {
        maxVideoWatchedTime = videoWatchedTime;
      }
    });
    // Iterate through the seriesDataMap and create lineCustomSeries

  for (const startDateLine in seriesDataMap) {
    const customSeries = {
      dataSource: seriesDataMap[startDateLine],
      xName: 'x',
      yName: 'y',
      name: startDateLine,
      width: '2',
      marker: { visible: true, width: 10, height: 10 },
      type: 'Line',
      visible: true, border: { width: 1, color: 'red' },
      
    };

    lineCustomSeriesMe.push(customSeries);
  }
  const LinePrimaryYAxis = {
    labelFormat: '{value}',
    rangePadding: 'None',
    minimum: 0,
    maximum: maxVideoWatchedTime,
    interval: 100,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
  };

  const LinePrimaryXAxis = {
    valueType: 'DateTime',
    labelFormat: 'MM/dd/yyyy', // Format to display both date and year
    intervalType: 'Days',
    edgeLabelPlacement: 'Shift',
    majorGridLines: { width: 0 },
    background: 'white',
  };
  // Function to convert time to minutes
  function convertTimeToMinutes(timeString) {
    const [hours, minutes] = timeString.split(':');
    return parseInt(hours) * 60 + parseInt(minutes);
  }
  

  return (
    <ChartComponent
      id={chartId}
      height="420px"
      primaryXAxis={LinePrimaryXAxis}
      primaryYAxis={LinePrimaryYAxis}
      chartArea={{ border: { width: 0 } }}
      tooltip={{ enable: true,enableMarker:true }}
      background={currentMode === 'Dark' ? '#33373E' : '#fff'}
      legendSettings={{ background: 'white' }}
    >
      <Inject services={[LineSeries, DateTime, Legend, Tooltip,DataLabel]} />
      <SeriesCollectionDirective>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {lineCustomSeriesMe.map((item, index) => <SeriesDirective  key={index} {...item}  />)}
      </SeriesCollectionDirective>
    </ChartComponent>
  );
};

export default LineChart;
