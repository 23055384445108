import React , { useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Header } from '../components';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import {
  getPatientEventApi,
  getEventQuestionsApi,
  getAppQuestionsApi,
} 
from '../restApis/api';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { useStateContext } from '../contexts/ContextProvider';
import { ChartsHeader, LineChart } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile } from '@fortawesome/free-solid-svg-icons/faFaceSmile'
import { faFaceMeh } from '@fortawesome/free-solid-svg-icons/faFaceMeh';
import { faFaceFrown } from '@fortawesome/free-solid-svg-icons/faFaceFrown';

const Kanban = () => {
  const { currentColor,userInfo } = useStateContext();
  const [expanded, setExpanded] = React.useState(null);
  const [patientEventData,setPatientEventData] = useState();
  const { id } = useParams(); // Use useParams inside the functional component
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenQuestion,setisModalOpenQuestion] =  useState(false);
  const [isAppModalOpenQuestion,setIsAppModalOpenQuestion] =  useState(false); 
  const [clickedVideoData, setClickedVideoData] = useState(null);
  const [patient,setPatient] = useState(null);
  const [questionaireMeta,setQuestionaireMeta] = useState(null);
  const [appQuestionaireMeta,setAppQuestionaireMeta] = useState(null);
  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
  let dialogInstance;


  const buttons = [{
    buttonModel: {
        content: 'Se connecter',
        cssClass: 'e-primary',
        isPrimary: true,
    },
    'click': () => {
        dialogInstance.hide();
        localStorage.clear();
        window.location.href = '/login';
        
    }
}];
  const handleCellClick = (row) => {
    setIsModalOpen(true);
    setClickedVideoData(row);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setClickedVideoData(null); // Clear the clicked video data when the modal is closed
  };
 
  const requestPatients = async ()=>{

    let res = await getPatientEventApi(id);
    let array = [];
    try {
      
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      if (res != null && res.data.code === 200) {
        res.data.data.events.forEach((element) => {
            array.push(element);
        });
        setPatientEventData(array);
        setPatient(res.data.data.patient);
        array.length > 0? setExpanded(array[0]['event_id']):null;

        
        return array;
      }else{
       console.log(res)
      }
    } catch (error) {
      console.log(error)
    }
  };

  const requestEventQuestion = async (idCurrentEvent)=>{

    let res = await getEventQuestionsApi(idCurrentEvent);
    let array = [];
    try {
      
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      if (res != null && res.data.code === 200) {
        // res.data.data.forEach((element) => {
        //     array.push(element);
        // });
        return res.data.data;
      }else{
       console.log(res)
       return array;
      }
    } catch (error) {
      console.log(error)
      return array;
    }
  };

  const requestAppQuestion = async (patientId)=>{

    let res = await getAppQuestionsApi(patientId);
    let array = [];
    try {
      
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      if (res != null && res.data.code === 200) {
        // res.data.data.forEach((element) => {
        //     array.push(element);
        // });
        return res.data.data;
      }else{
       console.log(res)
       return array;
      }
    } catch (error) {
      console.log(error)
      return array;
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const CustomAccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  const accordionData = patientEventData;


  const handleChange = (panel) => (
    newExpanded
  ) => {
    setExpanded(newExpanded ? panel : false);
  };


  const getMyQuestions = async (idEvent) => {
    setisModalOpenQuestion(true);
    const result = await requestEventQuestion(idEvent);
    setQuestionaireMeta(result);
    
  }
  const getMyAppQuestions = async (patient_id) => {
    setIsAppModalOpenQuestion(true);
    const result = await requestAppQuestion(patient_id);
    console.log(result);
    setAppQuestionaireMeta(result);
    
  }
 
  const handleCloseModalQuestion = () => {
    setQuestionaireMeta(null);
    setisModalOpenQuestion(false); // Clear State
  };


  const handleCloseModalAppQuestion = () => {
    setAppQuestionaireMeta(null);
    setIsAppModalOpenQuestion(false); // Clear State
  };
 
  const componentQuestionaire = ({ data,type }) => {
   if(type === 'event'){
    return (
      <Typography>
        {Object.entries(data).map(([key, value],index) => (
          <Typography key={key}>
            <Typography style={{ fontWeight: '500',fontSize:25 }}>{index +1} - {key}</Typography><span style={{ fontWeight: '400',fontSize:25  }}>  Répondre :</span>
            {value === 'Heureuse' ? (

                <FontAwesomeIcon icon={faFaceSmile} color={'#189e06'} size="2xl" style={{ marginLeft: 10,marginBottom:5  }} />
              ) : value === 'Confus' ? (
                <FontAwesomeIcon icon={faFaceMeh} color={'#cf5f04'} size="2xl" style={{ marginLeft: 10 ,marginBottom:5 }} />
              ) : (
                <>
                 <FontAwesomeIcon icon={faFaceFrown} color={'#c41508'} size="2xl" style={{ marginLeft: 10, marginRight: 10,marginBottom:5 }} />
                <span style={{ fontSize:25  }} >{value}</span>
                </>
               

              )}
            
          </Typography>
        ))}
      </Typography>
    );
   }else if(type === 'app'){
    console.log(type);
    return (
      <Typography>
      {Object.entries(data).map(([key, value],index) => (
        <Typography key={key}>
        
          <Typography style={{ fontWeight: '500',fontSize:25 }}>{index +1}- {key}</Typography>
          <span style={{ fontWeight: '400',fontSize:25,paddingLeft:30  }}>  Répondre : </span><span style={{ fontSize:25  }} >{value.radio}  </span>
     
          {value.input !== ""?(
            <><Typography style={{ fontWeight: '500', fontSize: 25,paddingLeft:30 }}>Commentaire : </Typography><span style={{ fontSize: 25 ,paddingLeft:35}}>{value.input}  </span></>
          ):(
                <></>
          )}
          
          
             
        </Typography>
        
        
      ))}
    </Typography>
    );
    
    
   }
    
  };

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        await requestPatients(); // Call method to fetch patients data
      } catch (error) {
        console.error('Error fetching patients:', error);
        // Initialize the dialogInstance if it's null
        if (!dialogInstance) {
          dialogInstance = new DialogComponent({ buttons });
          document.getElementById('dialog').appendChild(dialogInstance.createElement());
          dialogInstance.appendTo('#dialog');
        }
        dialogInstance.header = "Échec";
        dialogInstance.content = "La session a expiré!";
        dialogInstance.show();
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl" id='dialog-target'>
      <Header title="Détails des séances" />
      <div className="flex gap-10 flex-wrap ">
        <div className="bg-white dark:text-white-200  m-3 p-4 rounded-2xl md:w-780  ">
          <div className="flex justify-between">
            <p className="font-extrabold text-xl">Patient Details</p>
            { patient && patient.questions_submitted ?(
                  
                         <a
                     type="button"
                     className="text-undefined p-3  hover:drop-shadow-xl hover:bg-undefined"
                     style={{ backgroundColor: currentColor, color: 'white', borderRadius: '10px' ,minWidth:'30%',cursor:'pointer'}}
                    
                     onClick={() => getMyAppQuestions(id)}
                   >
                   Questions sur l'application disponibles
                   </a>
                  
                    
               
             ):(
                      ""
             ) }
          </div>
          <div className="mt-10 flex gap-10 flex-wrap">
            <div className=" border-r-1 border-color m-4 pr-10">
              <div>
               <p className="text-2xl text-dark mt-1 font-extrabold">Nom</p>
                <p>
                  <span className="font-semibold">{ patient ? patient.nom :""}</span>
                  
                </p>
                
              </div>
              <div className="mt-8">
              <p className="text-2xl text-dark mt-1 font-extrabold">Prénom</p>
                <p className="font-semibold">{patient ?patient.prenom :"" }</p>
              </div>
            
            </div>
            <div className="border-r-1 border-color m-4 pr-10">
              <div>
               <p className="text-2xl text-dark mt-1 font-extrabold">E-mail</p>
                <p>
                  <span className="font-semibold">{patient ?patient.email :""}</span>
                  
                </p>
                
              </div>
              <div className="mt-8">
              <p className="text-2xl text-dark mt-1 font-extrabold">Date de naissance</p>
                <p className="font-semibold">{patient ?patient.dob :""}</p>

              </div>
            
            </div>
            
            <div className="  border-color m-4 pr-10">
              <div>
               <p className="text-2xl text-dark mt-1 font-extrabold">Sexe</p>
                <p>
                  <span className="font-semibold">{patient ?patient.sexe:""}</span>
                  
                </p>
                
              </div>
            
            
            </div>
          </div>
        </div>
       
      </div>
      {/* chart Component */}
     
      <div>
          {accordionData && accordionData.length > 0 ? (
            accordionData.map((item) => (
              <Accordion
                key={item.event_id}
                expanded={expanded === item.event_id}
                onChange={handleChange(item.event_id)}
              >
                
                <CustomAccordionSummary
                  aria-controls={`${item.id}-content`}
                  id={`${item.event_id}-header`}
                >
                  
                  <Typography ><span className='font-extrabold '>{item.event_name}</span>-{'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}-<span className='font-extrabold '>Le:{'\u00A0'}{item.event_startTime}</span></Typography>
                  
                </CustomAccordionSummary>
               
                <AccordionDetails>
                  <Typography className='text-3xl font-extrabold tracking-tight text-slate-900'><span className='font-extrabold '>Description : </span> {item.event_description}</Typography>
                  {item.event_questions.length > 0 ? (
                      <Typography style={{marginTop:20,marginBottom:20}}>
                      <p  style={{ marginBottom: '10px' }} className="font-extrabold text-xl">Questionnaire d'évaluation de Qualité</p>
                     {item.event_questions.map((question, index) => ( 

                                          <a
                                          type="button"
                                          className="text-undefined p-3 w-full hover:drop-shadow-xl hover:bg-undefined"
                                          style={{ backgroundColor: currentColor, color: 'white', borderRadius: '10px',width: '10%' ,cursor:'pointer',marginRight:10}}

                                          onClick={() => getMyQuestions(question.question_id)}
                                          >
                                          Le - {question.question_date}
                                          </a>
                      
                     ))}
                   
                    
                   
                 </Typography>
                 
                  ):("")
                  }
                  
                  
                  
                  
                 
                  <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
                        <ChartsHeader category={item.event_name} title={""} />
                        <div className="w-full">
                          <LineChart value={item}/>
                        </div>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Mon activite</TableCell>
                          <TableCell align="right">Date</TableCell>
                          <TableCell align="right">Debut</TableCell>
                          <TableCell align="right">Fin</TableCell>
                          <TableCell align="right">Temps</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {item.sub_events.map((row) => (
                          <TableRow
                            key={row.sub_event_log_id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            <TableCell component="th" scope="row" onClick={() => handleCellClick(row)}>
                              {row.sub_event_video_name}
                            </TableCell>
                            <TableCell align="right">{row.sub_event_startDate !== null ? row.sub_event_startDate : '--/--'}</TableCell>
                            <TableCell align="right">{row.sub_event_debut !== null ? row.sub_event_debut : '00:00'}</TableCell>
                            <TableCell align="right">{row.sub_event_fin !== null ? row.sub_event_fin : '00:00'}</TableCell>
                            <TableCell align="right">{row.sub_event_video_watched_time !== null ? row.sub_event_video_watched_time : '00:00'}</TableCell>
                            
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              
            ))
          ) : (
            <Typography>No data available</Typography> // Render a message when data is not available
          )}
    </div>
    <DialogComponent id='dialog' header='Success' 
      buttons={buttons} 
      content='Description Saved Successfully!' width='250px' 
      isModal={true} ref={dialog => dialogInstance = dialog} 
      visible={false} 
      target='#dialog-target'/>
      {/* Render the ModalComponent dynamically */}
      
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
          }}
        >
          {clickedVideoData && (
            <video controls width="400">
              <source src={`${baseUrl}/${clickedVideoData.sub_event_video_path}`} type="video/mp4" />
              <source
                src={`${baseUrl}/${clickedVideoData.sub_event_video_path}`}
                type="video/webm"
              />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </Modal>
      {/* dialog for event questionaire */}
      <div>
      <Modal open={isModalOpenQuestion} onClose={handleCloseModalQuestion}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
            border:'1px solid',
            width:'60%',
            overflow: 'auto',  // Add overflow property for scrolling
            maxHeight: '75vh', // Set a maximum height for better responsiveness
          }}
        >
          {questionaireMeta && (
            componentQuestionaire({ data:  questionaireMeta,type:'event' }
             )
          )}
        </div>
      </Modal>
       
      </div>


       {/* dialog for app questionaire */}
       <div>
      <Modal open={isAppModalOpenQuestion} onClose={handleCloseModalAppQuestion}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            padding: '20px',
            outline: 'none',
            border:'1px solid',
            width:'60%',
            overflow: 'auto',  // Add overflow property for scrolling
            maxHeight: '75vh', // Set a maximum height for better responsiveness
          }}
        >
          {appQuestionaireMeta && (
            componentQuestionaire({ data:  appQuestionaireMeta ,type:'app'}
             )
          )}
        </div>
      </Modal>
       
      </div>
          </div>
  );
};

export default Kanban;
