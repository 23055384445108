import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { loginApi } from '../restApis/api';
import mainLogo from '../data/mainLogo.png';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Droits d\'auteur © '}
      <Link color="inherit" href="https://en.serviligne.net/">
        Serviligne Développement 
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Login = ({ setLoginSuccessful ,isLoginSuccessful}) => {
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
 
    const email = data.get('email');
    const password = data.get('password');
    const response = await loginApi(email,password);
    if (response.status == 200 ){
      if (response.data.code == 200) {
        
 
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('sessionItems', JSON.stringify({"user_meta": response.data}));
        setLoginSuccessful(true);

      }else{
        dialogInstance.header = "Échec";
        dialogInstance.content = "Email ou mot de passe invalide!";
        dialogInstance.show();
      }
    }else{
      dialogInstance.header = "Échec";
      dialogInstance.content = "Aucune réponse du serveur!";
      dialogInstance.show();
    }
 
  };
  let dialogInstance;
  const buttons = [{
    buttonModel: {
        content: 'Fermer',
        cssClass: 'e-primary',
        isPrimary: true,
    },
    'click': () => {
        dialogInstance.hide();
    }
}];
  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}  id='dialog-target'>
        <CssBaseline />
        <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: (t) =>
                t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
              height: '100vh', // Adjust the height as needed
            }}
          >
      <img
        src={mainLogo}
        alt="Logo"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </Grid>
        <Grid item xs={12} sm={8} md={7} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connectons-nous
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Adresse e-mail"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Se connecter
              </Button>
              <Grid container>
                <Grid item xs>
                  {/* <Link href="#" variant="body2">
                    Forgot password?
                  </Link> */}
                </Grid>
                <Grid item>
                  <Link href="#" variant="body2">
                    {"Termes et conditions"}
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
        <DialogComponent id='dialog' header='Success' 
      buttons={buttons} 
      content='Description/Fréquence enregistrée avec succès!' width='250px' 
      isModal={true} ref={dialog => dialogInstance = dialog} 
      visible={false} 
      target='#dialog-target'/>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;