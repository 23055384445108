import React , { useEffect,useState } from 'react';
import { 
  GridComponent,
   Inject,
   ColumnsDirective,
   ColumnDirective,
   Search, 
   Page, 
   Toolbar,
   Edit,
   CommandColumn,
   Filter,
  } from '@syncfusion/ej2-react-grids';
import {getPatientsApi} from '../restApis/api';
// import { employeesData, employeesGrid } from '../data/dummy';
import { Header } from '../components';
import { useTheme } from '@mui/material/styles';
import { useNavigate  } from 'react-router-dom';

const Employees = () => {
  const toolbarOptions = ['Search'];
  const navigate  = useNavigate();
  const [patientData, setPatientData] = useState(null); // State to track patients data
  const theme = useTheme();
  const editing = { allowDeleting: true,};
  const requestPatients = async ()=>{
    let res = await getPatientsApi();
    let array = [];
    try {
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      if (res != null && res.data.code === 200) {
        // totalCount = res.data.pagination.total_results;

        res.data.data.forEach((element) => {
          
            array.push(element);
          
        });
        setPatientData(array);
        return array;
      }else{
        localStorage.clear();
    
      }
      
      
    } catch (error) {
      localStorage.clear();
    }
  };





  useEffect(() => {
    const fetchData = async () => {
      try {
        await requestPatients(); // Call method to fetch patients data
      } catch (error) {
        console.error('Error fetching patients:', error);
        // // Redirect to login page
        // localStorage.clear();

        // window.location.href = '/login'; // Replace with your login page URL
      }
      
    };
    
    fetchData();
  }, []);

    let grid;
    const commands = [
        {
            buttonOption: {
                 cssClass: 'e-flat', iconCss: 'e-search e-icons'
            }
        }
    ];
   
    const commandClick = (args) => {
        if (grid) {
            
          const id = args.rowData.id;
    
          // Redirect to a new page with the id as a URL parameter
          navigate(`/kanban/${id}`);
           
            
        }
    };
    const customColumValue = (field, data, column) => {
      return data.latest_eventStartTime;
  };
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl" id='dialog-target'>
      <Header category="Page" title="Patients" />
      <GridComponent
        dataSource={patientData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        commandClick={commandClick}
        ref={g => grid = g}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ColumnDirective field='name' headerText='Nom' width='100' textAlign="RiLeftght" isPrimaryKey={true}/>
          <ColumnDirective field='surname' headerText='Prénom' width='120'/>
          <ColumnDirective field='formattedBirthday' headerText='Date de naissance' width='120'  textAlign="Left"/>
          <ColumnDirective field='mail' headerText='Email'  width='150'/>
          <ColumnDirective field='sex' headerText='Genre'  width='150'/>
          <ColumnDirective field='latest_eventStartTime' headerText="Début de l'événement"  width='150'/>
          <ColumnDirective field='latest_eventEndTime' headerText="Fin de l'événement"  width='150'/>

          <ColumnDirective headerText='Action' width='120' commands={commands}/>
          {/* {employeesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)} */}
        </ColumnsDirective>
        
        <Inject services={[Search, Toolbar ,Page,CommandColumn,Filter]} />

      </GridComponent>

    {/* Dialoge Component */}

   
    </div>
  );
};
export default Employees;
