import React,{ useEffect,useState }  from 'react';
import { UploaderComponent,UploadingEventArgs  } from '@syncfusion/ej2-react-inputs';
import { ordersData, contextMenuItems,  } from '../data/dummy';
import { GridComponent, ColumnsDirective, ColumnDirective, Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport, Edit, Inject,Selection } from '@syncfusion/ej2-react-grids';
import {
  getTokenFromLS,
  getMediaApi,
  getCategoriesApi,
  updateVideoCategory,
  getVideoCategory,
  getallVideoCategories,
  getCategoryVideo,
  getAllCategoryVideos,
  updateCategoryVideo,
} 
from '../restApis/api';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Autocomplete from "@mui/material/Autocomplete";
const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";

// import { ordersData, contextMenuItems, ordersGrid } from '../data/dummy';
import { Header } from '../components';

const FileManager = () => {
    const [mediaData, setMediaData] = useState(null); // State to track patients data
    const [selectedMediaRowData, setSelectedMediaRowData] = useState({}); // To store the selected row data
    const [selectedCategoryRowData, setSelectedCategoryRowData] = useState({}); // To store the selected row data
    const [openMediadialog, setOpenMediaDialog] = React.useState(false);
    const [openCategoryDialog, setOpenCategoryDialog] = React.useState(false);
    const [name, setName] = useState(''); // Initialize with empty string
    const [nameCategory,setNameCategory] = useState('');
    const [categoryData, setCategory] = useState('');
    const [videoCategory, setvideoCategory] = useState('');
    const [valueCategories, setMediaValue] = useState();
    const [valueCategoryVideos, setCategoriesValue] = useState();
    const [videoLoading, setVideoLoading] = useState(true);
    const [allVideosCatogry,setAllVideosCategory] = useState('');
    const [allCatogryVideos,setAllCategoryVideos] = useState('');

    const spinner = () => {
      setVideoLoading(!videoLoading);
    };

    const path = {
        removeUrl: 'https://ej2.syncfusion.com/services/api/uploadbox/Remove',
        saveUrl: baseUrl+'/api/v1/professional/postMedia',
        // Add custom headers to the request
        customHeaders: [
            { name: 'Authorization', value: 'Bearer your_access_token_here' },
            { name: 'Custom-Header', value: 'Custom-Value' }
        ]
    };
    function onUploadSuccess(args) {
        if (args.operation === 'upload') {
            // window.console.log('File uploaded successfully');
            window.location.reload();
        }
    }
    function onUploadFailure(args) {
        // window.console.log('File failed to upload');
    }
    function addHeaders(UploadingEventArgs) {
        let token = getTokenFromLS(); 
        try {
            if (token == undefined || token == null) {
                return "refresh Failed"
              } 
              let tokenHeader = token.user_meta.token;
          
            (UploadingEventArgs.currentRequest ).setRequestHeader('authorization',  "Bearer " + tokenHeader );
        } catch (error) {
            console.log(error);
        }
       
        }


    const mediaGrid = [
        
            {
              field: 'name',
              headerText: 'Nom de la vidéo',
              width: '150',
              editType: 'dropdownedit',
              textAlign: 'Center',
            },
            { field: 'path',
              headerText: 'Nom du fichier vidéo',
              width: '150',
              textAlign: 'Center',
            },
            
          
            {
              field: 'createdAt',
              headerText: 'Ajouté le',
              width: '150',
              textAlign: 'Center',
            },
          ];
    const categoryGrid = [
  
      {
        field: 'name',
        headerText: 'nom de la catégorie',
        width: '150',
        editType: 'dropdownedit',
        textAlign: 'Center',
      },
      {
        field: 'createdAt',
        headerText: 'Ajouté le',
        width: '150',
        textAlign: 'Center',
      },
    ];
          
  const requestMedia = async ()=>{
      let res = await getMediaApi();
      let array = [];
      try {
          if (res === "refresh Failed") {
          console.log("session expired!")
          }
          if (res != null && res.data.code === 200) {
          // totalCount = res.data.pagination.total_results;

          res.data.data.forEach((element) => {
              
              array.push(element);
              
          });
          setMediaData(array);
          return array;
          }else{
          localStorage.clear();
      
          }
          
          
      } catch (error) {
          localStorage.clear();
      }
  };
  const requestCategory = async ()=>{
    let res = await getCategoriesApi();
    let array = [];
    try {
        if (res === "refresh Failed") {
        console.log("session expired!")
        }
        if (res != null && res.data.code === 200) {
        // totalCount = res.data.pagination.total_results;

        res.data.data.forEach((element) => {
            
            array.push(element);
            
        });
        setCategory(array);
        return array;
        }else{
        localStorage.clear();
    
        }
        
        
    } catch (error) {
        localStorage.clear();
    }
};
    
    const handleSaveMedia = async () => {
      // Access the name and category values from the state
    
        // console.log('Name:', name);
        // console.log('Category:', category);
        let videoId = selectedMediaRowData['id'];
       
   
       
        //CALL UPDATED VIDEO API
        let res = await updateVideoCategory({id:videoId,category:valueCategories,name:name});
        if (res != null && res.data.code === 200) {
              window.location.reload();
       
        }
        setName('');
        setOpenMediaDialog(false);
      };

    const handleSaveCategory = async () => {
      // Access the name and category values from the state
    
        // console.log('Name:', name);
        // console.log('Category:', category);
        let catId = selectedCategoryRowData['id'];
        console.log(valueCategoryVideos,nameCategory,catId);
    
        
        //CALL UPDATED CATRGORY API
        let res = await updateCategoryVideo({id:catId,videos:valueCategoryVideos,name:nameCategory});
        if (res != null && res.data.code === 200) {
          window.location.reload();
        }
        setOpenCategoryDialog(false);
        setNameCategory('');

      };


    const handleCloseMedia = () =>{
      setOpenMediaDialog(false);
      
    };
    let gridMedia;
    const rowSelectedMedia = async () => {
      if (gridMedia) {
        let array = [];
        let array2 = [];
        /** Get the selected row indexes */
        const selectedrowindex = gridMedia.getSelectedRowIndexes();
        /** Get the selected records. */
        const selectedrecords = gridMedia.getSelectedRecords();
    
        // Use a callback with setState to ensure immediate state update
        setSelectedMediaRowData(prevSelectedRowData => selectedrecords[0]);
        //call video api to get categories
        let res = await getVideoCategory(selectedrecords[0]['id']);

        if (res != null && res.data.code === 200) {
          res.data.data.forEach((element) => {
            
              array.push(element);
            
          });
        }
        let res2 = await getallVideoCategories(selectedrecords[0]['id']);
        if (res2 != null && res2.data.code === 200) {
          res2.data.data.forEach((element) => {
            
              array2.push(element);
            
          });
        }
        setvideoCategory(array);
        
        setAllVideosCategory(array2);
        setMediaValue(array);
        // setDialogVisibility(true);
        setOpenMediaDialog(true);
      }
        
      
    }

    const handleCloseCategory = () =>{
      setOpenCategoryDialog(false);
      
    };
    let gridCategory;
    const rowSelectedCatrgory = async () => {
      if(gridCategory){
        let array = [];
        let array2 = [];
        const selectedrowcategoryIndex = gridCategory.getSelectedRowIndexes();
        /** Get the selected records. */
        const selecteMediaRecords = gridCategory.getSelectedRecords();
        // Use a callback with setState to ensure immediate state update
        setSelectedCategoryRowData(prevSelectedCategoryRowData => selecteMediaRecords[0]);
        let res = await getCategoryVideo(selecteMediaRecords[0]['id']);

        if (res != null && res.data.code === 200) {
          res.data.data.forEach((element) => {
            
              array.push(element);
            
          });
        }
        let res2 = await getAllCategoryVideos(selecteMediaRecords[0]['id']);
        if (res2 != null && res2.data.code === 200) {
          res2.data.data.forEach((element) => {
            
              array2.push(element);
            
          });
        }
        setAllCategoryVideos(array2)
        setCategoriesValue(array);
        setOpenCategoryDialog(true);
      }
    
    }

   
    const frameStyle = {
      backgroundColor: 'lightgray',
      width: '100%',
      border: '1px solid darkgray'
    };
   

    useEffect(() => {
    const fetchData = async () => {
        try {
        await requestMedia();
        await requestCategory(); // Call method to fetch patients data
        } catch (error) {
        console.error('Error fetching patients:', error);
        // Redirect to login page
        localStorage.clear();

        window.location.href = '/'; // Replace with your login page URL
        }
        
    };
    
    fetchData();
    }, []);
  
    const handleKeyDownMedia = event => {
      switch (event.key) {
        case ",":
        case " ": {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.length > 0) {
              const newValue = { id: 0, name: event.target.value };
              setMediaValue([...valueCategories, newValue]);
              event.target.value = ''; // Clear the input after adding the value
          }
          break;
        }
        default:
      }
    };

    const handleKeyDownCategory = event => {
      switch (event.key) {
        case ",":
        case " ": {
          event.preventDefault();
          event.stopPropagation();
          if (event.target.value.length > 0) {
              const newValueCategory = { id: 0, name: event.target.value };
              setCategoriesValue([...valueCategoryVideos, newValueCategory]);
              event.target.value = ''; // Clear the input after adding the value
          }
          break;
        }
        default:
      }
    };

  return (
    <div className="App" id='dialog-target'>
         <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
            <Header category="Page" title="Gestion des fichiers vidéo" />
            <UploaderComponent name='files' 
            autoUpload={false} 
            asyncSettings={path} 
            uploading={addHeaders=addHeaders.bind(this)}
            success={onUploadSuccess = onUploadSuccess.bind(this)} 
            failure={onUploadFailure = onUploadFailure.bind(this)} 
            removing={addHeaders=addHeaders.bind(this)}
            allowedExtensions='.mp4, .webm., .mov, .mkv'
            maxFileSize={209715200}
            />
        </div>
        <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header  title="Vidéo destinées aux séances" />

          <GridComponent
        id="gridcomp"
        dataSource={mediaData}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        rowSelected={rowSelectedMedia} ref={g => gridMedia = g} // Attach the rowSelected event handler

        
        >

       
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {mediaGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Selection]} />
          </GridComponent>

      </div>
      <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
        <Header  title="Catégories" />

          <GridComponent
        id="gridcompCategory"
        dataSource={categoryData}
        allowPaging
        allowSorting
        allowExcelExport
        allowPdfExport
        contextMenuItems={contextMenuItems}
        rowSelected={rowSelectedCatrgory} ref={g => gridCategory = g} // Attach the rowSelected event handler

        
        >

       
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {categoryGrid.map((item, index) => <ColumnDirective key={index} {...item} />)}
        </ColumnsDirective>
        <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, Edit, PdfExport,Selection]} />
          </GridComponent>

      </div>
      {/* media dialoge box start */}
        <Dialog open={openMediadialog} onClose={handleCloseMedia}    fullWidth={true}
                maxWidth={true} >
                <DialogTitle>Détails de la vidéo</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {selectedMediaRowData['name']}
                  </DialogContentText>
                  <div>
                  <iframe   style={frameStyle}
               
                  className="modal__video-style"
                  onLoad={spinner}
                  loading="lazy"
                  width="100"
                  height="500"

                  src={baseUrl+'/'+selectedMediaRowData['path']}
                  title="YouTube video player"
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  muted
                  >
                  
                  </iframe>
                  </div>
                
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Nom"
                    type="name"
                    fullWidth
                    variant="standard"
                    value={name !== '' ? name : selectedMediaRowData['name']}
                    onChange={(e) => setName(e.target.value)} // Update the name state

                    
                  />
           
                    <div style={{ width: 500 }}>
                            <Autocomplete
                              multiple
                              freeSolo
                              id="tags-outlined-media"
                              options={allVideosCatogry}
                              getOptionLabel={option => option.name || option}
                              value={valueCategories}
                              onChange={(event, newValue) => setMediaValue(newValue)}
                              filterSelectedOptions
                              renderInput={params => {
                                params.inputProps.onKeyDown = handleKeyDownMedia;
                                return (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Catégories"
                                    placeholder="Favorites"
                                    margin="normal"
                                    fullWidth
                                  />
                                );
                              }}
                            />
                </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseMedia}>Annuler</Button>
                  <Button onClick={handleSaveMedia}>Sauvegarder</Button>
                </DialogActions>
        </Dialog>
        {/* category dialoge box start */}
        <Dialog open={openCategoryDialog} onClose={handleCloseCategory}    fullWidth={true}
                maxWidth={true} >
                <DialogTitle>Détail de la catégorie</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {selectedCategoryRowData['name']}
                  </DialogContentText>
             
                
                  <TextField
                    autoFocus
                    margin="dense"
                    id="nameCategory"
                    label="Name"
                    type="name"
                    fullWidth
                    variant="standard"
                    value={nameCategory !== '' ? nameCategory : selectedCategoryRowData['name']}
                    onChange={(e) => setNameCategory(e.target.value)} // Update the name state

                    
                  />
               
                    <div style={{ width: 500 }}>
                            <Autocomplete
                              multiple
                              id="tags-outlined-category"
                              options={allCatogryVideos}
                              getOptionLabel={option => option.name || option}
                              value={valueCategoryVideos}
                              onChange={(event, newValue) => setCategoriesValue(newValue)}
                              filterSelectedOptions
                              renderInput={params => {
                                params.inputProps.onKeyDown = handleKeyDownCategory;
                                return (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Vidéos de la séance"
                                    placeholder="Vidéos de la séance"
                                    margin="normal"
                                    fullWidth
                                  />
                                );
                              }}
                            />
                </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleCloseCategory}>Annuler</Button>
                  <Button onClick={handleSaveCategory}>Sauvegarder</Button>
                </DialogActions>
        </Dialog>                       


       
        </div>
   
    
    );
};
export default FileManager;
