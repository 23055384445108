import React, { useState,useEffect,useRef } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Month, Inject, Resize, } from '@syncfusion/ej2-react-schedule';
import { DatePickerComponent,DateTimePickerComponent  } from '@syncfusion/ej2-react-calendars';
import { DataManager, ODataV4Adaptor, Query,  } from '@syncfusion/ej2-data';
// import { MultiSelect  } from '@syncfusion/ej2-react-dropdowns';
import { Header } from '../components';
// import { createElement } from '@syncfusion/ej2-base';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import {saveEvent,getEventsApi} from '../restApis/api';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DialogComponent } from '@syncfusion/ej2-react-popups';

import { L10n } from '@syncfusion/ej2-base';
L10n.load({
  'en-US': {
    'schedule': {
      'saveButton': 'Ajouter',
      'cancelButton': 'Fermer',
      'deleteButton': 'Retirer',
      'newEvent': 'Ajouter un programme',
    },
  }
});
const URL = process.env.REACT_APP_API_URL || "http://localhost:3001";

// eslint-disable-next-line react/destructuring-assignment
const PropertyPane = (props) => <div className="mt-5">{props.children}</div>;

const Scheduler = () => {
  const scheduleRef = useRef(null);
  const [eventData, setEventData] = useState(null); // State to track event data
  const [eventDataCopy, setEventDataCopy] = useState(null); // State to track event data
  const startObj = useRef(null);
  const endObj = useRef(null);
  let dialogInstance;
  const buttons = [{
    buttonModel: {
        content: 'Fermer',
        cssClass: 'e-primary',
        isPrimary: true,
    },
    'click': () => {
        dialogInstance.hide();
    }
}];
  const change = async (args) => {
    
    const existingElement = document.getElementById('spnMessage');
    if (existingElement) {
      existingElement.remove(); 
    }
    const span = document.createElement('span');
    span.id = 'spnMessage';

    if (args.value){
      // Keep a copy of the original data
    
      new DataManager(eventDataCopy).executeQuery(new Query().where('Patient','equal',args.value,true)).then((e) => {
        if (e.result.length > 0) {
          setEventData(e.result);
          scheduleRef.current?.element.parentNode?.insertBefore(span, scheduleRef.current?.element);
          if (span.style) {
              span.style.color = '#32a852';
              span.innerHTML = "Données d'événement trouvées !";
          }
        }
        else {
          scheduleRef.current?.element.parentNode?.insertBefore(span, scheduleRef.current?.element);
          if (span.style) {
              span.style.color = '#FF0000';
          }
          span.innerHTML = "Aucune donnée d'événement trouvée!";
          setEventData();
        }
    });
    }else{
      const eventsDataManager = await requestEvents(); // Call method to fetch patients data
      setEventData(eventsDataManager);
    }
  };
  const handleClear = () => {
    // Your logic when the clear button is clicked
    console.log("Clear button clicked!");
  };
  const onDragStart = (arg) => {
    // eslint-disable-next-line no-param-reassign
    arg.navigation.enable = true;
  };

  const requestEvents = async ()=>{
    let res = await getEventsApi();
    let array = [];
    try {
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      
      if (res != null && res.data.code === 200) {
        // totalCount = res.data.pagination.total_results;
        
        res.data.data.forEach((element) => {
            array.push(element);
          
        });
        
        
        return array;
      }else{
        // localStorage.clear();
        return array;
      }
      
      
    } catch (error) {

        console.log(error);
        return [];
      // localStorage.clear();
    }
  };
  

  // sort the resulted items
  const sortOrder = 'Ascending';

//   const onPopupOpen = (args) => {
//     if (args.type === 'Editor') {
//         if (!args.element.querySelector('.custom-field-row')) {
//           const searchDataPatiens = new DataManager({
//             adaptor: new ODataV4Adaptor(),
//             crossDomain: true,
//             url: URL+'/api/v1/professional/getPatientForEvents',
//           });
//           const searchDataVideos = new DataManager({
//             adaptor: new ODataV4Adaptor(),
//             crossDomain: true,
//             url: URL+'/api/v1/professional/getVideosForEvents',
//           });

//           // maps the appropriate column to fields property
//           const fields = { text: 'fullName', value: 'id' };
//           const fieldsVideo = { text: 'name', value: 'id' };
//           // filtering event handler to filter a patients
//           const onFiltering = (e) => {
//             // set limit as 4 to search result
//             let newQuery = new Query().select(['surname','name', 'id']).take(4);
//             newQuery = e.text !== '' ? newQuery.where('name','equal',e.text,true) : newQuery;
//             e.updateData(searchDataPatiens, newQuery);
//           };

//            // filtering event handler to filter a video
//            const onFilteringVideos = (e) => {
//             // set limit as 4 to search result
//             let newQuery = new Query().select(['name', 'id']).take(4);
//             newQuery = e.text !== '' ? newQuery.where('name','equal',e.text,true) : newQuery;
//             e.updateData(searchDataVideos, newQuery);
//           };

//           // bind the Query instance to query property
//           const query = new Query().select(['id', 'name']).take(7);
//           const queryVideo = new Query().select(['id', 'name']).take(7);

//           let row = createElement('div', { className: 'custom-field-row' });
//           let formElement = args.element.querySelector('.e-schedule-form');
//           formElement.firstChild.insertBefore(row, formElement.firstChild.firstChild);
//           let container = createElement('div', { className: 'custom-field-container' });
//           let inputElePatient = createElement('input', {
//               className: 'e-field', attrs: { name: 'Patient' }
//           });
//           let inputEleVideos = createElement('input', {
//             className: 'e-field', attrs: { name: 'Videos' }
//         });
//             let validator = formElement.ej2_instances[0];
//             validator.addRules('Patient', { required: true });
//             validator.addRules('Videos', { required: true });
//             container.appendChild(inputElePatient);
//             container.appendChild(inputEleVideos);
//             row.appendChild(container);
//             let drowDownListPatient = new DropDownList({
//               dataSource: searchDataPatiens,
//               allowFiltering:true,
//               fields: fields,
//               popupHeight:'250px',
//               popupWidth:'500px',
//               filtering:onFiltering,
//               sortOrder:sortOrder,
//               query:query,
//               value: args.data.Patient,
//               floatLabelType: 'Always', placeholder: 'Patient'
              
//           })
//           drowDownListPatient.appendTo(inputElePatient);
//           inputElePatient.setAttribute('name', 'Patient');
          
//           let drowDownListVideos = new MultiSelect({
//             dataSource: searchDataVideos,
//             allowFiltering:true,
//             fields: fieldsVideo,
//             popupHeight:'250px',
//             popupWidth:'500px',
//             filtering:onFilteringVideos,
//             sortOrder:sortOrder,
//             query:queryVideo,
//             value: ["Video 1"],
//             mode: 'Box', // Set mode to "Box" for multi-select
//             floatLabelType: 'Always', 
//             placeholder: 'Videos'
            
            
//         })
//         drowDownListVideos.appendTo(inputEleVideos);
//         inputEleVideos.setAttribute('name', 'Video');
        
//         }

//     }
// }
const onPopupOpen = (args) => {
  if (args.type === 'Editor') {
    let statusElement = args.element.querySelector('#EventType');
    if (statusElement) {
      statusElement.setAttribute('name', 'EventType');
    }
    
  }
}
const onPopupClose = (args) => {
  if (args.type === 'Editor' && !isNullOrUndefined(args.data)) {
    let subjectElement = args.element.querySelector('#Summary');
    if (subjectElement) {
      args.data.Subject = subjectElement.value;
    }
    let statusElement = args.element.querySelector('#EventType');
    if (statusElement) {
      args.data.EventType = statusElement.value;
    }
    args.data.StartTime = startObj.current.value;
    args.data.EndTime = endObj.current.value;
    let descriptionElement = args.element.querySelector('#Description');
    if (descriptionElement) {
      args.data.Description = descriptionElement.value;
    }
  }
}
  const onActionComplete = async (args) => {
    // This event handler is triggered after an action is completed (e.g., eventCreated).
    if (args.requestType === 'eventCreated') {
      // This condition checks if the action is creating a new event.
      
      // You can access the newly created event data in the args.data object.
      const newEvent = args.data[0];
      //send post request to save the event 
      
      // Now, you have access to the data of the newly created event.
      console.log('New event created:', newEvent);
      let res = await saveEvent(args.data[0]);
      try {
        if (res === "refresh Failed") {
         console.log("session expired!")
        }
        if (res != null && res.data.code === 200) {
          dialogInstance.header = "Succès";
          dialogInstance.content = "Programme enregistré avec succès!";
          dialogInstance.show();
        }else{
          dialogInstance.header = "Échec";
          dialogInstance.content = "Échec de l'enregistrement du nouveau programme!";
          dialogInstance.show();
      
        }
      } catch (error) {
        dialogInstance.header = "Échec";
        dialogInstance.content = "Serveur hors-service!";
        dialogInstance.show();
      }
      // You can perform additional actions with the newly created event data here.
    }
  };

  //rules for event 
  const minValidation = (args) => {
    return args['value'].length >= 5;
};
  const fieldsData = {
    id: 'Id',
    subject: { name: 'Subject', validation: { required: true } },
    description: {
        name: 'Description', validation: {
            required: true, minLength: [minValidation, 'Need atleast 5 letters to be entered']
        }
    },
    startTime: { name: 'StartTime', validation: { required: true } },
    endTime: { name: 'EndTime', validation: { required: true } },

}

const editorTemplate = (props) => {
  const searchDataPatiens = new DataManager({
                adaptor: new ODataV4Adaptor(),
                crossDomain: true,
                url: URL+'/api/v1/professional/getPatientForEvents',
              });
  const searchDataVideos = new DataManager({
                adaptor: new ODataV4Adaptor(),
                crossDomain: true,
                url: URL+'/api/v1/professional/getVideosForEvents',
              });
  // maps the appropriate column to fields property
  const fields = { text: 'fullName', value: 'id' };
  const fieldsVideo = { text: 'name', value: 'id' };

  // filtering event handler to filter a patients
  const onFiltering = (e) => {
    // set limit as 4 to search result
    let newQuery = new Query().select(['surname','name', 'id']).take(4);
    newQuery = e.text !== '' ? newQuery.where('name','equal',e.text,true) : newQuery;
    e.updateData(searchDataPatiens, newQuery);
  };
  // filtering event handler to filter a video
  const onFilteringVideos = (e) => {
  // set limit as 4 to search result
    let newQuery = new Query().select(['name', 'id']).take(4);
    newQuery = e.text !== '' ? newQuery.where('name','equal',e.text,true) : newQuery;
    e.updateData(searchDataVideos, newQuery);
  };
  // bind the Query instance to query property
  const query = new Query().select(['id', 'name']).take(7);
  const queryVideo = new Query().select(['id', 'name']).take(7);

  return (props !== undefined ? <table className="custom-event-editor" ><tbody>
    <tr><td className="e-textlabel">Nom de l'événement</td><td colSpan={4}>
      <input required id="Summary" className="e-field e-input" type="text" name="Subject"  />
    </td></tr>
    <tr><td className="e-textlabel">Patient</td><td colSpan={4}>
    <DropDownListComponent 
        id="ddlelement" 
        name="Patient"
        allowFiltering={true} 
        popupHeight="250px" 
        filtering={onFiltering} 
        sortOrder={sortOrder}
        query={query} 
        dataSource={searchDataPatiens} 
        fields={fields} 
        className="e-field"
        placeholder="Sélectionnez un patient"/>
    </td></tr>
    <tr><td className="e-textlabel">Vidéos</td><td colSpan={4}>
    <MultiSelectComponent 
        name="Video"
        id="mlelementVideo" 
        allowFiltering={true} 
        popupHeight="250px" 
        filtering={onFilteringVideos} 
        sortOrder={sortOrder}
        query={queryVideo} 
        dataSource={searchDataVideos} 
        fields={fieldsVideo} 
        className="e-field"
        placeholder="Sélectionnez des vidéos"/>
        
    </td></tr> 
    
    <tr><td className="e-textlabel">Depuis</td><td colSpan={4}>
      <DateTimePickerComponent format='dd/MM/yy hh:mm a' id="StartTime" data-name="StartTime" value={new Date(props.startTime || props.StartTime)} className="e-field"></DateTimePickerComponent>
    </td></tr>
    <tr><td className="e-textlabel">À</td><td colSpan={4}>
      <DateTimePickerComponent format='dd/MM/yy hh:mm a' id="EndTime" data-name="EndTime" value={new Date(props.endTime || props.EndTime)} className="e-field"></DateTimePickerComponent>
    </td></tr>
    <tr><td className="e-textlabel">Description du Programme</td><td colSpan={4}>
      <textarea id="Description" className="e-field e-input" name="Description" rows={3} cols={50} ></textarea>
    </td></tr></tbody></table> : <div></div>);
}
const onActionFailure = () => {
  const span = document.createElement('span');
  scheduleRef.current?.element.parentNode?.insertBefore(span, scheduleRef.current?.element);
  if (span.style) {
      span.style.color = '#FF0000';
  }
  span.innerHTML = 'Server exception: 404 Not found';
};


useEffect(() => {
  const fetchData = async () => {
    try {
      const eventsDataManager = await requestEvents(); // Call method to fetch patients data
      setEventData(eventsDataManager);
      setEventDataCopy(eventsDataManager);
    } catch (error) {
      console.error('Error fetching patients:', error);
      // Redirect to login page
      localStorage.clear();

      window.location.href = '/login'; // Replace with your login page URL
    }
    
  };
  
  fetchData();
}, []);

const searchDataPatiensCalendar = new DataManager({
  adaptor: new ODataV4Adaptor(),
  crossDomain: true,
  url: URL+'/api/v1/professional/getPatientForEvents',
});
const fieldsCalendarDropDownFilter = { text: 'fullName', value: 'id' };
const queryCalendar = new Query().select(['id', 'name']).take(7);
const onFilteringCalendar = (e) => {
  // set limit as 4 to search result
  let newQuery = new Query().select(['surname','name', 'id']).take(4);
  newQuery = e.text !== '' ? newQuery.where('name','equal',e.text,true) : newQuery;
  e.updateData(searchDataPatiensCalendar, newQuery);
};
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl" id='dialog-target'>
      <Header category="App" title="Calendrier" />
      {/* <DropDownListComponent
      id="ddlelement"
      allowFiltering={true}
      popupHeight="250px"
      filtering={onFiltering}
      sortOrder={sortOrder}
      query={query}
      dataSource={searchData}
      fields={fields}
      placeholder="Select a customer"
    /> */}
    <PropertyPane>
        <table
          style={{ width: '100%', background: 'white' }}
        >
          <tbody>
            <tr style={{ height: '50px' }}>
              <td style={{ width: '100%' }}>
                {/* <DatePickerComponent
                  value={new Date()}
                  showClearButton={false}
                  placeholder="Patient"
                  floatLabelType="Always"
                  change={change}
                /> */}
                <DropDownListComponent 
                    id="CalendarPatientFilter" 
                    name="Patient"
                    allowFiltering={true} 
                    popupHeight="250px" 
                    filtering={onFilteringCalendar} 
                    sortOrder={sortOrder}
                    query={queryCalendar}
                    onChange={change} 
                    dataSource={searchDataPatiensCalendar} 
                    fields={fieldsCalendarDropDownFilter} 
                    className="e-field"
                    placeholder="Sélectionnez un patient"
                    showClearButton={true} // Enable clear button
                    clearButtonIcon="e-icons e-clear-icon" // Set the icon for the clear button
                    onClear={handleClear} // Call the handleClear function when the clear button is clicked


                    />
              </td>
            </tr>
          </tbody>
        </table>
      </PropertyPane>
      <ScheduleComponent
        width='100%'
        height='600px'
        ref={scheduleRef}        
        selectedDate={new Date()}
        eventSettings={{includeFiltersInQuery: true, dataSource: eventData,fields: fieldsData }}
        dragStart={onDragStart}
        actionFailure={onActionFailure}
        popupOpen={onPopupOpen.bind(this)}
        actionComplete={onActionComplete}
        editorTemplate={editorTemplate.bind(this)}
      >
        <ViewsDirective>
          { ['Month', ].map((item) => <ViewDirective key={item} option={item} />)}
        </ViewsDirective>
        <Inject services={[Month, Resize, ]} />
      </ScheduleComponent>
      <DialogComponent id='dialog' header='Success' 
      buttons={buttons} 
      content='Description/Fréquence enregistrée avec succès!' width='250px' 
      isModal={true} ref={dialog => dialogInstance = dialog} 
      visible={false} 
      target='#dialog-target'/>
    </div>
  );
};

export default Scheduler;
