import React , { useEffect,useState } from 'react';
import { 
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search, 
  Page, 
  Edit,
  Filter,
} from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { Header } from '../components';
import {getEventsDetailedApi,updateSubEvent} from '../restApis/api';
L10n.load({
  'en-US': {
      grid: {
          'SaveButton': 'Confirmer',
          'CancelButton': 'Annuler'
      }
  }
});
const Events = () => {
  const toolbarOptions = ['Search'];
  const editing = { allowDeleting: true, allowEditing: true ,mode: 'Dialog',allowEditOnDblClick:true};
  const [eventsDetailed, setEventsDetailed] = useState(null); // State to track patients data
  let dialogInstance;
  const buttons = [{
          buttonModel: {
              content: 'Fermer',
              cssClass: 'e-primary',
              isPrimary: true,
          },
          'click': () => {
              dialogInstance.hide();
          }
      }];
  const requestEventsDetailed = async ()=>{
    let res = await getEventsDetailedApi();
    let array = [];
    try {
      if (res === "refresh Failed") {
       console.log("session expired!")
      }
      if (res != null && res.data.code === 200) {
        // totalCount = res.data.pagination.total_results;

        res.data.data.forEach((element) => {
          
            array.push(element);
          
        });
        setEventsDetailed(array);
        return array;
      }else{
        localStorage.clear();
    
      }
      
      
    } catch (error) {
      localStorage.clear();
    }
  };
  const actionComplete = async (args) => {
    if (args.requestType === 'beginEdit'){
      args.dialog.width = '50%'
      args.dialog.header  = 'Modifier la description/Fréquence  de '+args.rowData['event_name'];
    }
    if(args.action === 'edit'){
      console.log(args.data);
      
      let res = await updateSubEvent(args.data);
      try {
        if (res === "refresh Failed") {
         console.log("session expired!")
        }
        if (res != null && res.data.code === 200) {
          dialogInstance.header = "Succès";
          dialogInstance.content = "Description mise à jour avec succès!";
          dialogInstance.show();
        }else{
          dialogInstance.header = "Échec";
          dialogInstance.content = "Échec de la mise à jour de la description!";
          dialogInstance.show();
      
        }
      } catch (error) {
        dialogInstance.header = "Échec";
        dialogInstance.content = "Serveur hors-service!";
        dialogInstance.show();
      }
      
    }
}
  useEffect(() => {
    const fetchData = async () => {
      try {
        await requestEventsDetailed(); // Call method to fetch patients data
      } catch (error) {
        console.error('Error fetching Events:', error);
        // Redirect to login page
        localStorage.clear();

        window.location.href = '/login'; // Replace with your login page URL
      }
      
    };
    
    fetchData();
  }, []);
  let grid;
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl" id='dialog-target'>
      <Header category="Page" title="Événements" />
      <GridComponent
        id="gridcomp"
        dataSource={eventsDetailed}
        allowPaging
        allowSorting
        toolbar={toolbarOptions}
        editSettings={editing}
        ref={g => grid = g}
        actionComplete={actionComplete}
       

      >
         <ColumnsDirective>
         <ColumnDirective field='sub_event_id' headerText='ID' visible={false} width='100' textAlign="Right" isPrimaryKey={true}/>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <ColumnDirective 
            field='event_name' 
            headerText="Nom de l'événement" 
            width='100' textAlign="RiLeftght" 
            allowEditing={false}
          />
          <ColumnDirective field='patient_name' headerText='Nom du patient' width='120' allowEditing={false}/>
          <ColumnDirective field='video_name' headerText='Vidéo' width='120'  textAlign="Left" allowEditing={false}/>
          <ColumnDirective field='subevent_description' headerText='Description'  width='150'/>
          <ColumnDirective field='subevent_frequency' headerText='Fréquence'  width='150'/>
          <ColumnDirective field='event_startDate' headerText='Date de début'  width='150' allowEditing={false}/>
          <ColumnDirective field='event_endDate' headerText='Date de fin'  width='150' allowEditing={false}/>
          {/* <ColumnDirective headerText='Action' width='120' commands={commands}/> */}
          {/* {employeesGrid.map((item, index) => <ColumnDirective key={index} {...item} />)} */}
        </ColumnsDirective>
        <Inject services={[Search, Filter, Page, Edit]} />
      </GridComponent>
      <DialogComponent id='dialog' header='Success' 
      buttons={buttons} 
      content='Description/Fréquence enregistrée avec succès!' width='250px' 
      isModal={true} ref={dialog => dialogInstance = dialog} 
      visible={false} 
      target='#dialog-target'/>
    </div>
  );
};
export default Events;
